.container {
  background-color: transparent;
  display: flex;
  flex-direction: column;
  justify-content: start;
  flex: 1;
  width: 100%;
  font-family: Kanit, sans-serif;
}

.header {
  margin: 5px 0 5px 0;
  display: flex;
  flex-direction: row;
  /* width: 100%; */
  justify-content: space-between;
}

.info-content {
  display: flex;
  background-color: #000000;
  flex: 1;
  padding: 40px;
  align-content: center;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.content {
  display: flex;
  background-color: #000000;
  /* flex: 1; */
  padding: 40px;
  align-content: center;
  justify-content: center;
  flex-direction: column;
}

.logo {
  margin: 10px 0 10px 20px;
  width: 120px;
  height: auto;
}

.walletContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.walletBalance {
  display: flex;
  margin: 0px 10px;
  font-weight: 600;
  font-size: medium;
  align-items: center;
}

.walletAddressButton {
  align-items: center;
  display: flex;
  justify-content: center;
  /* background-color: #353546; */
  margin-right: 20px;
  align-self: center;
  border-radius: 25px;
  border: none;
  color: white;
  cursor: pointer;
}

.wallet {
  margin: 0px 10px 0px 0px;

  display: flex;
  padding: 5px;
  font-weight: 600;
  font-size: medium;
  color: #000000;
}

.walletIcon {
  margin: 2px 0px 2px 5px;
  width: 30px;
  height: 30px;
}

.ethIcon {
  margin: 2px;
  width: 20px;
  height: 20px;
}

.dialog {
  width: 300px;
  background-color: #eee;
  text-align: center;
  padding: 27px 18px;
  margin-bottom: 27px;
  color: black;
  word-wrap: break-word;
}

.container h1 {
  margin: 0;
  padding-bottom: 18px;
  font-size: 18px;
}

.container h2 {
  margin: 4px;
  padding: 0px;
  font-size: 15px;
}

.container span {
  padding: 5px;
}

.container form {
  background-color: rgb(48 54 73);

  display: flex;
  align-self: center;
  padding: 10px;
  border-radius: 16px;
  flex-direction: column;
  font-size: 18px;
  max-width: 436px;
  width: 260px;
}

.content .dialog {
  background-color: rgb(48 54 73);
  color: white;
  display: flex;
  align-self: center;
  padding: 10px;
  border-radius: 16px;
  flex-direction: column;
  font-size: 18px;
  max-width: 436px;
  width: 260px;
}

.container input {
  font-size: 16px;
  padding: 10px 24px;
  margin: 8px 0px;
  border-radius: 16px;
  border-style: none;
  background-color: rgb(24 26 37);
  color: white;
  border-style: none;
  font-weight: 600;
}

.content .dialog-title {
  margin-top: 5px;
  padding: 5px;
}

.content .dialog-sub-title {
  margin: 10px 0px;
  padding: 5px;
}

.content button {
  padding: 9px;
  font-size: 18px;
  margin: 10px 0px;
  border-radius: 16px;
  background-color: rgb(31 199 212);
  border-style: none;
  color: white;
  font-weight: 600;
  cursor: pointer;
}

.content .google-button {
  background-color: #e44532;
}

.content .apple-button {
  background-color: #000000;
}

.container input.full-width {
  display: block;
  margin: 0 auto;
  margin-bottom: 9px;
  text-align: center;
}

.balance {
  max-width: 21ch;
  margin: 0px 0px 18px 0px;
  /* background-color: #ddd; */
  padding: 0px;
  word-wrap: break-word;
  /* font-family: "Lucida Console", Monaco, monospace; */
  font-size: 36px;
  align-self: center;
}

.info {
  max-width: 21ch;
  margin: 0 auto;
  margin-bottom: 18px;
  background-color: #ddd;
  padding: 12px 24px;
  word-wrap: break-word;
  font-family: "Lucida Console", Monaco, monospace;
  font-size: 15px;
}

.info a {
  color: black;
}

.generalButton {
  color: white;
  background-color: transparent;
  padding: 5px 10px;
  margin: 10px;
  align-self: center;
  border: none;
  font-size: 15px;
  cursor: pointer;
}

.error {
  color: #e44532;
  font-size: 18px;
}
