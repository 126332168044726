/* Callback.css */

.callback-container {
    margin-top: 30px;
    text-align: center;
    font-size: 18pt;
}

.process-completed {
    font-weight: bold;
    color: green;
    margin-bottom: 10px; 
}

.logo-image {
    width: 200px;
    height: 200px;
    margin: 10px auto; 
}

.open-app-button {
    background-color: rgb(33, 34, 90);
    padding: 10px 20px;
    cursor: pointer;
    color: white !important;
    text-decoration: none;
    border-radius: 5px;
    display: block;
    margin: 20px auto;
}

@media only screen and (max-width: 600px) {
    .logo-image {
        width: 80%;
        height: auto; 
        margin: 20px auto;
    }

    .open-app-button {
        margin-top: 20px;
        display: inline-block; 
        width: 50%; 
    }
}

